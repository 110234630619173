<template>
  <div class="footer-wrap">
    <div class="footer-content" :class="align">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "center",
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 50px;
  height: 50px;
  right: 0;
  z-index: 99;

  .footer-content {
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
  }

  .left {
    justify-content: flex-start;
  }

  .center {
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
  }
}
</style>
