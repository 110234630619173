<template>
  <div class="app-container pb-50px">
    <el-card shadow="never">
      <el-page-header
        :content="identity ? '编辑管理' : '新增管理'"
        @back="$router.back()"
      ></el-page-header>
    </el-card>
    <el-card class="mt-16px" v-loading="loading" shadow="never">
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        size="small"
        label-width="100px"
      >
        <el-form-item label="管理账号" prop="account">
          <el-input
            v-model="form.account"
            :disabled="form.root == 1"
            style="width: 480px"
            show-word-limit
            placeholder="请输入管理账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理名称" prop="name">
          <el-input
            v-model="form.name"
            style="width: 480px"
            show-word-limit
            placeholder="请输入管理名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="form.password"
            style="width: 480px"
            show-password
            clearable
            placeholder="请输入密码"
          />
        </el-form-item>

        <el-form-item label="确认密码" prop="password_confirm">
          <el-input
            v-model="form.password_confirm"
            style="width: 480px"
            show-password
            clearable
            placeholder="请输入确认密码"
          />
        </el-form-item>

        <el-form-item label="管理状态" v-if="form.root != 1">
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-text="启用"
            inactive-text="停用"
          />
        </el-form-item>
      </el-form>
    </el-card>
    <footer-wrap>
      <el-button size="small" @click="$router.back()">取 消</el-button>
      <el-button type="primary" size="small" @click="onSubmit">
        保 存
      </el-button>
    </footer-wrap>
  </div>
</template>

<script>
import FooterWrap from "@/components/footer-wrap/index.vue";

import {
  apiDetailAdministrator,
  apiAddAdministrator,
  apiEditAdministrator,
} from "@/api/perm";

export default {
  components: {
    FooterWrap,
  },
  data() {
    const passwordConfirmValidator = (rule, value, callback) => {
      if (this.form.password) {
        if (!value) callback(new Error("请再次输入密码"));
        if (value !== this.form.password)
          callback(new Error("两次输入密码不一致!"));
      }
      callback();
    };
    return {
      identity: null,
      loading: false,
      form: {
        id: "",
        account: "",
        name: "",
        password: "",
        password_confirm: "",
        status: 0,
        root: 0,
      },
      rules: {
        account: [
          { required: true, trigger: "blur", message: "请输入管理账号" },
        ],
        name: [{ required: true, trigger: "blur", message: "请输入管理名称" }],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur"],
          },
        ],
        password_confirm: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: ["blur"],
          },
          {
            validator: passwordConfirmValidator,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.identity = this.$route.query.id;
    this.identity && this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      apiDetailAdministrator({ id: this.identity }).then((resp) => {
        Object.keys(resp).map((item) => {
          this.$set(this.form, item, resp[item]);
        });
        this.rules.password = [];
        this.rules.password_confirm = [
          {
            validator: this.passwordConfirmValidator,
            trigger: "blur",
          },
        ];
        console.log(this.rules);
        this.loading = false;
      });
    },
    onSubmit() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        if (this.identity) {
          apiEditAdministrator({ ...this.form, id: this.identity }).then(() => {
            this.$router.back();
          });
        } else {
          apiAddAdministrator(this.form).then(() => {
            this.$router.back();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
