import request from "@/utils/request";

/**
 * 获取管理员列表
 * @param {*} params
 * @returns
 */
export function apiListAdministrator(params) {
  return request.get("/administrator/list", params);
}

/**
 * 获取管理员详情
 * @param {*} params
 * @returns
 */
export function apiDetailAdministrator(params) {
  return request.get("/administrator/detail", params);
}

/**
 * 添加管理员
 * @param {*} params
 * @returns
 */
export function apiAddAdministrator(params) {
  return request.post("/administrator/add", params);
}

/**
 * 编辑管理员
 * @param {*} params
 * @returns
 */
export function apiEditAdministrator(params) {
  return request.post("/administrator/edit", params);
}

/**
 * 删除管理员
 * @param {*} params
 * @returns
 */
export function apiDeleteAdministrator(params) {
  return request.post("/administrator/delete", params);
}
